import React, { useState, useEffect, useContext } from "react"
import ReactDOM from "react-dom";
import scrollTo from "gatsby-plugin-smoothscroll"
import { useCookies } from "react-cookie"
import { Link, navigate } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { faPen } from "@fortawesome/free-solid-svg-icons"
import CartContext from "../../context/cartContext"
import CheckoutContact from "../CheckoutContact"
import CheckoutShippingAddress from "../CheckoutShippingAddress"
import CheckoutBlock from "../CheckoutBlock"
import CheckoutRadio from "../CheckoutRadio"
import CheckoutContext from "../../context/checkoutContext"
import { CardElement,PaymentRequestButtonElement, useStripe, useElements} from '@stripe/react-stripe-js';
import NewButton from "../NewButton"
import axios from "axios";
import { buildFrames } from "../../services/FramesDictionary"
import PrescriptionRadio from "../PrescriptionRadio"
import paypal from 'paypal-checkout';
import braintree from 'braintree-web';
import CustomerContext from "../../context/customerContext";
import { lensesData } from "./mockedData";
import {getCadence, getValues} from "../../services/utils"
import { useWindowWidth } from "../../services/hooks"
import {julyPriceTestClassic, quantityOptionsValues} from "../ContactsSummary/mockedData"
import { zipRegEx } from "../../utils/variables";

import "./style.scss"
import { usePromo, promoStart, promoEnd } from "../../utils/usePromo";
import CheckoutOrderInfo from "../CheckoutOrderInfo";
import useNewRxFlow from "../../utils/useNewRxFlow";
import WebpImage from '../WebpImage';
import useIsLifemart from '../Lifemart/hooks/useIsLifemart';
import useIsMilitary from '../Military/hooks/useIsMilitary';
import useNewRxFlowDesktop from "../../utils/useNewRxFlowDesktop";
import useAbTest from '../../utils/useAbTest'
import CheckoutDuplicateModal from "../CheckoutDuplicateModal";
import {getHubblePricing} from "../../services/data";


const CheckoutCustomerInfo = ({ backTo }) => {
  const {stripeCustomer, setStripeCustomer, shippingPrice} = useContext(CustomerContext)
  const [active, setActive] = useState()
  const [isMobile, setIsMobile] = useState()
  const [cookies, setCookie] = useCookies(["user-jwt","gclid","utm_source", "utm_medium", "utm_content", "utm_campaign"])
  const userToken = cookies["user-jwt"]
  const [newUserToken, setNewUserToken] = useState('')
  const gclid = cookies["gclid"]
  const utm_source = cookies["utm_source"]
  const utm_medium = cookies["utm_medium"]
  const utm_content = cookies["utm_content"]
  const utm_campaign = cookies["utm_campaign"]

  const stripe = useStripe();
  const elements = useElements();
  let subTotal = 0
  let line_items = []
  const ga_items = []
  const prescriptions= []
  let contact_lenses = []
  const [buttonText, setButtonText] = useState("Place Order")
  const [ironclad, setIronclad] = useState("")
  const [errorCopy, setErrorCopy] = useState("There is an error with your payment")
  const [isPaymentError, setIsPaymentError] = useState(false)
  const framesDictionary = buildFrames()
  const [customerData, setCustomerData] = useState(JSON.parse(localStorage.getItem("customer_data")))
  const [paypallPaymentId, setPaypallPaymentId] = useState(null)
  const [isSubmittedOrder, setIsSubmittedOrder] = useState(false)
  const [checkoutButtonDisabled, setCheckoutButtonDisabled] = useState(false)
  const [ironcladWarning, setIronCladWarining] = useState(false)

  const [isMobilePay, setMobilePay] = useState(false)
  const [applePay, setApplePay] = useState("")
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [taxLoaded, setTaxLoaded] = useState(false)
  const [policiesCheck, setPoliciesCheck] = useState('')
  const [policiesError, setPoliciesError] = useState(false)
  const discountInputElement = typeof document !== "undefined" ? document.querySelector(".discount-input") : null
  const isPromo = usePromo(promoStart, promoEnd)
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()
  const windowWidth = useWindowWidth()
  const isLifemart = useIsLifemart()
  const isMilitary = useIsMilitary()
  const isPaidShipping = useAbTest()
  const [emojiErrors, setEmojiErrors] = useState({})
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false)
  const [isAgreeDuplicate, setIsAgreeDuplicate] = useState(false)
  const hubblePricing = getHubblePricing()

  useEffect(() => {
    setIsMobile(windowWidth < 600)
}, [windowWidth])

  const handleCheckPolicies = () => {
    setPoliciesCheck(policiesCheck === "policies" ? "" : "policies")
  }

  useEffect(() => {
    setCustomerData(JSON.parse(localStorage.getItem("customer_data")))
  }, [userToken])

  const {
    isValid,
    setIsValid,
    setPaymentMethod,
    customer,
    shippingAddress,
    paymentMethod,
    billingAddress,
    setIsRequiredMessage,
    setShippingAddress,
    setIsAddressShouldReset,
  } = useContext(CheckoutContext)

  const {
    taxes,
    cartItems,
    discountPercentage,
    discountCode,
    setDiscountCode,
    setDiscountPercentage,
    setTaxes,
    clearOrder,
    updateItem,
  } = useContext(CartContext)

  const [isSunglassesDiscount, setIsSunglassesDiscount] = useState(JSON.parse(localStorage.getItem("discount_code"))?.isSunglassesDiscount)
  const [isFramesDiscount, setIsFramesDiscount] = useState(JSON.parse(localStorage.getItem("frames_discount")))
  const hasContactsAvg = cartItems?.some(e => e.type === "contacts" && e.isAvg)

  useEffect(() => {
    setIsSunglassesDiscount(JSON.parse(localStorage.getItem("discount_code"))?.isSunglassesDiscount)
    setIsFramesDiscount(JSON.parse(localStorage.getItem("frames_discount")))
  }, [cartItems, discountPercentage])



  const mapCartItems = () => {
    line_items = []
    const hubble_lenses = cartItems.filter(obj => {
      return obj.type === "contacts"
    })

    contact_lenses = hubble_lenses[0]
    // frames map

    cartItems.map( (a) =>{
      if (a.type === "glasses" || a.type === "sunglasses") {
        Array.from({ length: a.quantity}).map( () => {
          const lens_color = a.type === "sunglasses" ? framesDictionary[a.title]["sunglasses"][a.variant.title] : ""
          const sunglassesDiscount = ((isSunglassesDiscount && a.type === "sunglasses") || (!isSunglassesDiscount && !isFramesDiscount)) ? discountPercentage : 0
          const framesDiscount = ((!isSunglassesDiscount && isFramesDiscount) || (!isSunglassesDiscount && !isFramesDiscount)) ? discountPercentage : 0
          const discount = a.type === "sunglasses" ? sunglassesDiscount : framesDiscount

          line_items.push({product_id: a.shopifyProductId, product_name: a.title, variant_id: a.variant.shopifyVariantId, sku: a.variant.sku, frame_number: a.id, line_item_type: 'frame', variant_title: a.variant.title, price: discount ? a.price - a.price * discount : a.price, lens_color: lens_color })
          line_items.push({product_name: a.material.title, product_id: a.material.product_id, variant_id: a.material.variant_id, price: 0,  sku: a.material.sku , frame_number: a.id, line_item_type: 'lens_material'})
          line_items.push({product_name: a.prescriptionType.title, product_id: a.prescriptionType.product_id, variant_id: a.prescriptionType.variant_id, price: 0,  sku: a.prescriptionType.sku , frame_number: a.id, line_item_type: 'prescription_type'})
          line_items.push({product_name: a.lensType.value, product_id: a.lensType.product_id ,variant_id: a.lensType.variant_id, price: 0, sku: a.lensType.sku, frame_number: a.id, line_item_type: 'lens_type'})
          if(a.prescriptionType.sku === "NONRX01" ){
            prescriptions.push({rightSph: null , rightCyl: null, rightAxis: null, leftSph: null, leftCyl: null , leftAxis: null,  pd: null, frame_number: a.id, line_item_type: 'prescription' })
          }
          else if(a.prescriptionType.sku === 'RL01') {
            prescriptions.push({rightSph:a.prescription.rightEye.sphere , rightCyl: null, rightAxis: null, leftSph:a.prescription.leftEye.sphere, leftCyl: null , leftAxis: null,  pd: null, frame_number: a.id, line_item_type: 'prescription' })
          }
          else {
            prescriptions.push({rightSph:a.prescription.rightEye.sphere , rightCyl: a.prescription.rightEye.cylinder, rightAxis: a.prescription.rightEye.axis, leftSph:a.prescription.leftEye.sphere, leftCyl: a.prescription.leftEye.cylinder , leftAxis: a.prescription.leftEye.axis,  pd: a.prescription.pd, frame_number: a.id, line_item_type: 'prescription' })
          }
          const item_total = parseFloat(a.price)
          subTotal += discount ? (item_total - item_total * discount) : item_total
        })
      }
      else if(a.type === "accessory") {
        const discount = (!isSunglassesDiscount && !isFramesDiscount) ? discountPercentage : 0
        const item = {
          item_name: a.title,
          currency: "USD",
          discount: 0,
          item_brand: 'Hubble',
          item_category: "accessory",
          variant_id: a.variant_id,
          product_id: a.product_id,
          quantity: a.quantity,
          price: discount ? a.price - a.price * discount : a.price,
          line_item_type: 'accessory'
        }
        if (customerData) {item.item_id = a.product_id}
        line_items.push(item)
        subTotal += discount ? parseFloat(a.price - a.price * discount) : parseFloat(a.price)
      }
    })

    if (contact_lenses) {
      const newRxPrescription = Object.entries(contact_lenses.prescription).reduce(
      (result, [eye, values]) => {
        if (!contact_lenses.isOneEye || (contact_lenses.isOneEye && values.power !== "oneEye")) {
          result[eye] = {
            power: values.power,
            baseCurve: values.baseCurve,
            diameter: values.diameter,
          }
        }
        return result
      },
      {}
    )

      const contactsType = () => {
        if (contact_lenses.title?.toLowerCase()?.includes("hydro")) return "hydro"
        if (contact_lenses.title?.toLowerCase()?.includes("skyhy")) return "skyhy"
        if (contact_lenses.isAvg) return "cc"

        return "classic"
      }

      const quantity = contact_lenses?.lens_quantity / 30
      const isNonSubscription = contact_lenses?.nonSubscription
      const isOtp = contact_lenses?.otpPurchase

      const nonSubName = lensesData[contactsType()]?.nonSubName
      const subscriptionName = lensesData[contactsType()]?.subName

      const lineItemType = !isNonSubscription ? 'contacts' : isOtp ? "non-subscription contacts v2" : 'non-subscription contacts'
      const subProductId = lensesData[contactsType()]?.subProductId
      const nonSubProductId = lensesData[contactsType()]?.nonSubProductId
      const nonSubVariantId = lensesData[contactsType()]?.nonSubVariantId

      const nonSubPrice = contact_lenses.price
      const subPrice = contactsType() === "classic" && !contact_lenses.is_duplicate_item ? "1.00" : contact_lenses.price

      const item = !contact_lenses.isAvg ? {
        item_name: !isNonSubscription ? subscriptionName : nonSubName,
        currency: "USD",
        discount: 0,
        item_brand: 'Hubble',
        cadence: contact_lenses?.lens_quantity === 30 ? (contact_lenses?.cadence * 7) : (contact_lenses?.cadence * 28),
        item_category: "contacts",
        quantity: contact_lenses?.isOneEye ? quantity : quantity * 2,
        price: !isNonSubscription ? subPrice : nonSubPrice,
        prescription: isNonSubscription ? undefined : !isNewRx ? contact_lenses.prescription : newRxPrescription,
        line_item_type: lineItemType,
        contacts_type: contact_lenses.contacts_type,
        other_products: contact_lenses?.otherProducts ? contact_lenses?.otherProducts : "",
        lens_quantity: contact_lenses?.lens_quantity,
        product_id: !isNonSubscription ? subProductId : nonSubProductId,
        variant_id: isNonSubscription ? nonSubVariantId : null,
      } : {
        item_name: contact_lenses.title,
        currency: "USD",
        discount: 0,
        cadence: contact_lenses.cadence,
        item_category: "contacts",
        quantity: contact_lenses?.isOneEye ? quantity : quantity * 2,
        price: contact_lenses.sale_price || contact_lenses.price,
        prescription: contact_lenses.prescription,
        base_curve: contact_lenses?.baseCurve,
        add_power: contact_lenses?.addPower,
        axis: contact_lenses?.axis,
        diameter: contact_lenses?.diameter,
        line_item_type: lineItemType,
        contacts_type:  "cc",
        other_products: contact_lenses?.otherProducts ? contact_lenses?.otherProducts : "",
        lens_quantity: contact_lenses?.lens_quantity,
        product_id: contact_lenses.id,
        variant_id: null,
      }

      line_items.push(item)

      subTotal+= (contactsType() === "classic" ? 1 : contact_lenses.price)
    }

    cartItems.map( (a) =>{
      let item_category = a.type
      let item_category2 = a.type

      // item category logic
      if (item_category === "glasses" || item_category === "sunglasses"){
        item_category = "frames"
      }
      if (item_category === "accessory"){
        item_category = "accessories"
      }
      /// item category 2 logic
      if (item_category2 === "glasses") {
        item_category2 = "optical"
      }
      if (item_category2 === "contacts") {
        item_category2 = "spherical"
      }

      if (item_category2 === "accessory"){
        item_category2 = "contact accessories"
      }

      ga_items.push({
        index: 0,
        item_id: a.product_id || a.shopifyProductId || "2167100637257",
        item_name: a.title,
        item_list_id: 'N/A',
        item_list_name: 'N/A',
        currency: "USD",
        discount: 0,
        item_brand: "Hubble",
        item_category: item_category,
        item_category2: item_category2,
        item_category3: 'N/A',
        item_category4: 'N/A',
        item_category5: 'N/A',
        item_variant:  a.variant?.title || a.title,
        price: parseFloat(discountPercentage ? a.price - a.price * discountPercentage : a.price),
        quantity: 1
      })
    })
    // accessories map
    return line_items
  }


  mapCartItems()

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
          items: ga_items
        }
      });
    }
  },[])

  useEffect(() => {
    if (!stripe) {
      // We can't create a PaymentRequest until Stripe.js loads.
      return;
    }

    const isNonSubscription = !!cartItems.find((item) => item.type === "contacts" && item.nonSubscription)
    const isClassic = !!cartItems.find((item) => item?.contacts_type == "classic")
    const isHydro = !!cartItems.find((item) => item?.isHydro)
    const isSkyhy = !!cartItems.find((item) => item?.isSkyhy)
    const isAvg = !!cartItems.find((item) => item?.isAvg)
    const stripeTotal = parseFloat(cartItems?.reduce(
      (result, { price, sale_price, isOneEye, type, quantity }) =>
        (result += (sale_price || price) * quantity * (type === "contacts" && !isOneEye && Number(price) !== 1 ? 2 : 1)),
      0
    ) + ((isHydro || isSkyhy || isAvg || (isClassic && (isPaidShipping || isNonSubscription))) ? shippingPrice : 0) + parseFloat(taxes || 0)).toFixed(2)

    const pr = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: 'Hubble Contacts',
        amount: Math.round((parseFloat(stripeTotal) * 100)),
      },
      requestPayerName: true,
      requestPayerEmail: true
    });

    pr.on('paymentmethod', async (ev) => {
      setMobilePay(true)
      setApplePay(ev.paymentMethod.id)
      ev.complete('success');
    })

    pr.canMakePayment().then((canMakePaymentRes) => {
      if (canMakePaymentRes) {
        setPaymentRequest(pr);
      }
    }).catch((e)=>{
      console.log("cant make payment")
      console.log(e)
    })
  }, [stripe]);

  const updatePaymentRequest = async () => {
    const isNonSubscription = !!cartItems.find((item) => item.type === "contacts" && item.nonSubscription)
    const isClassic = !!cartItems.find((item) => item?.contacts_type == "classic")
    const isHydro = !!cartItems.find((item) => item?.isHydro)
    const isSkyhy = !!cartItems.find((item) => item?.isSkyhy)
    const isAvg = !!cartItems.find((item) => item?.isAvg)
    const stripeTotal = parseFloat(cartItems?.reduce(
      (result, { price, sale_price, isOneEye, quantity, type, glassesBasePrice, sunglassesBasePrice }) => {
        const validDiscount = ['gift40'].includes(discountCode?.toLocaleLowerCase()) && discountPercentage
        if (isPromo && validDiscount && (type === "glasses" || type === "sunglasses")) {
          const framesBasePrice = type === "glasses" ? glassesBasePrice : sunglassesBasePrice
          price = price - framesBasePrice * discountPercentage
        } else if (isLifemart && (type === "glasses" || type === "sunglasses")) {
          price = price * 0.75
        }
        return (result += (sale_price || price) * quantity * (type === "contacts" && !isOneEye && Number(price) !== 1 ? 2 : 1))
      },
      0
    ) + ((isHydro || isSkyhy || isAvg || (isClassic && (isPaidShipping || isNonSubscription))) ? shippingPrice : 0) + parseFloat(taxes || 0)).toFixed(2)

    if (paymentRequest && stripeTotal) {
      try {
        paymentRequest.update({
          total: {
            label: 'Hubble Contacts',
            amount: Math.round((parseFloat(stripeTotal) * 100)),
          }
        });
      } catch (error) {
        console.log("Couldn't update Link price", error)
      }
    }
  }


  useEffect(() => {
    if(isValid.shippingAddress && zipRegEx.test(shippingAddress.zip)){
      if (typeof window !== "undefined") {
        const isNonSubscription = !!cartItems.find((item) => item.type === "contacts" && item.nonSubscription)
        const isClassic = !!cartItems.find((item) => item?.contacts_type == "classic")
        const isHydro = !!cartItems.find((item) => item?.isHydro)
        const isSkyhy = !!cartItems.find((item) => item?.isSkyhy)
        const isAvg = !!cartItems.find((item) => item?.isAvg)
        const tax_amount = parseFloat(cartItems?.reduce(
          (result, { price, sale_price, isOneEye, quantity, type, glassesBasePrice, sunglassesBasePrice }) => {
            const validDiscount = ['gift40'].includes(discountCode?.toLocaleLowerCase()) && discountPercentage
            const framesBasePrice = type === "glasses" ? glassesBasePrice : sunglassesBasePrice

            if (isPromo && validDiscount && (type === "glasses" || type === "sunglasses")) {
              price = price - framesBasePrice * discountPercentage
            } else if (isLifemart && (type === "glasses" || type === "sunglasses")) {
              price = price * 0.75
            }

            return (result += (sale_price || price) * quantity * (type === "contacts" && !isOneEye && Number(price) !== 1 ? 2 : 1))
          },
          0
        ) + ((isHydro || isSkyhy || isAvg || (isClassic && (isPaidShipping || isNonSubscription))) ? shippingPrice : 0)).toFixed(2)

          // call query to api here
          const tax_params = {...shippingAddress, subTotal: tax_amount}

          axios.post(`${process.env.GATSBY_API_URL}/api/v1/avalara/get_tax_rate`,{tax_params}, { headers: {
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Credentials':true,
            'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          }}).then((response) => {
            console.log(response)
            setTaxLoaded(true)
            const tax_rate_amounts = response.data?.tax_rate_amounts
            if (+tax_params.subTotal === 1) {
              return setTaxes(0.00)
            }

            setTaxes(tax_rate_amounts?.toFixed(2))
          })

          window.dataLayer.push({ ecommerce: null })
          window.dataLayer.push({
            event: "shipping_details",
            shipping_state: shippingAddress.state,
            shipping_city: shippingAddress.city
          });
      }
    } else {
      setTaxes(0.00)
    }
  },[isValid.shippingAddress, shippingAddress.zip, discountCode, cartItems])




  const stripeSubmitCheckout = async (values, userToken) => {

    const headers = {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    }

    await axios.post(`${process.env.GATSBY_API_URL}/api/v1/stripe/checkout`, values, headers
    ).then((response) => {
      const isNonSubscription = !!cartItems.find((item) => item.type === "contacts" && item.nonSubscription)
      const isClassic = !!cartItems.find((item) => item?.contacts_type == "classic")
      const isHydro = !!cartItems.find((item) => item?.isHydro)
      const isSkyhy = !!cartItems.find((item) => item?.isSkyhy)
      const isAvg = !!cartItems.find((item) => item?.isAvg)

      const totalPrice = parseFloat(cartItems?.reduce(
        (result, { price, quantity }) => (result += price * quantity),
        0
      ) + ((isHydro || isSkyhy || isAvg || (isClassic && (isPaidShipping || isNonSubscription))) ? shippingPrice : 0)).toFixed(2)

      if (response.data.client_secret === "There was an error with your payment" || response.data.client_secret === undefined) {
        // setCardErrors("There was an error with your payment" )
        // setSubmitCopy("Complete Order")
        setCheckoutButtonDisabled(false)
        setIsPaymentError(true)
        setButtonText("Try again")
        return
      }
      if (typeof window !== "undefined") {
        if (window.gtag !== null) {
          if (!customer?.email.includes("hubble")){
            window.dataLayer.push({ ecommerce: null })
            window.dataLayer.push({
              event: "purchase",
              ecommerce: {
                transaction_id: `${btoa(customer?.email)}-${Date.now()}`,
                value: parseFloat(totalPrice),
                tax: 0,
                shipping: 0,
                currency: "USD",
                coupon: "N/A",
                paymentType: "stripe",
                items: ga_items
              }
            });
          }
        }
      }
      setButtonText("Place Order")
      // after successful order placement remove the discount code from localStorage
      clearOrder()
      navigate("/success-order", { state: { isSuccess: true, orderNumber: `${btoa(customer?.email)}-${Date.now()}`, orderType: "contacts" , price: totalPrice , email: customer?.email }  })
    }).catch((error) => {
      if (error.response?.data?.errors?.length && error.response.data.errors.some(e => e.includes("You already made a purchase of classic lenses with special price"))) {
        handleShowDuplicateDialog()
        setErrorCopy("")
      } else if (error.response?.data?.errors?.length && error.response.data?.errors[0] === "discount already used by customer") {
        setErrorCopy("This discount code has already been used")
      } else if (error.response?.data.errors?.length && error.response?.data?.errors[0] === "you can't use this promo with trial product") {
        setErrorCopy("You can't use this promo with trial product")
      } else if (error.response?.data.errors?.length && error.response?.data?.errors[0]) {
        setErrorCopy(error.response.data.errors[0].slice(0, 1).toUpperCase() + error.response.data.errors[0].slice(1))
      } else {
        setErrorCopy("There is an error with your payment")
      }

      setDiscountCode("")
      setDiscountPercentage(null)
      discountInputElement.value = "";
      setCheckoutButtonDisabled(false)
      setIsPaymentError(true)
      setButtonText("Try again")
      // setCardErrors("There was a problem with your payment")
      // setSubmitCopy("Complete Order")
    })
  }

  const stripeUserCreate =  async ( values ) => {
    const headers = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }
    }
    await axios.post(`${process.env.GATSBY_API_URL}/api/v1/stripe/sign_up`, values, { headers })
    .then((response) => {
      const userToken = response.data.token
      setNewUserToken(response.data.token)

      const headers = {
        headers: {
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          Authorization: `Bearer ${userToken}`
        }
      }

      axios.get(`${process.env.GATSBY_API_URL}/api/v1/stripe/customers`, headers)
    .then(async (response) => {
      setCustomerData({...response.data, type: "stripe"})
      setStripeCustomer({...response.data, type: "stripe"})

      const classicItem = cartItems.find(e => e.title?.toLowerCase().includes("hubble classic"))
      if (classicItem &&
        !classicItem.nonSubscription &&
        response.data?.special_discount_flags?.some(e => e.dup_flow) &&
        !isAgreeDuplicate &&
        response.data.shipping.name === `${shippingAddress.first_name} ${shippingAddress.last_name}`) {
        const orders = await axios.get(`${process.env.GATSBY_API_URL}/api/v1/stripe/customers/orders`, headers)

        if (orders.data?.orders.find(e =>
            e?.lenses?.order_lens_type === "classic" &&
            !["error", "refunded"].includes(e.fulfillment_status.toLowerCase()) &&
            e.lenses.right_eye_power === (classicItem.prescription?.rightEye?.power === "oneEye" ? undefined : classicItem.prescription?.rightEye?.power) &&
            e.lenses.left_eye_power === (classicItem.prescription?.leftEye?.power === "oneEye" ? undefined : classicItem.prescription?.leftEye?.power)
          )) {
          setCheckoutButtonDisabled(false)
          setButtonText("Try again")
          handleShowDuplicateDialog()
          return
        }
      }

      if (typeof window !== "undefined") {
        if (window.gtag !== null) {
          window.dataLayer.push({'user_id': response.data.id})
          // window.dataLayer.push({event: "login", method: "email"})
        }
      }
      values = { ...values, stripe_customer_id: response.data.id}
      stripeSubmitCheckout(values, userToken)
    })
    }).catch((reason) => {
      const responseErrors = reason?.response.data.errors

      if (responseErrors?.some(e => e.includes("emoji"))) {
        // setCardErrors("Emojis are not allowed")
        let _emojisError = emojiErrors
        if (responseErrors.find(e => e.includes("emoji") && e.includes("first_name"))) _emojisError = {..._emojisError, first_name: true}
        if (responseErrors.find(e => e.includes("emoji") && e.includes("last_name"))) _emojisError = {..._emojisError, last_name: true}
        if (responseErrors.find(e => e.includes("emoji") && e.includes("email"))) _emojisError= {..._emojisError, email: true}
        setEmojiErrors(_emojisError)
      }
      setCheckoutButtonDisabled(false)
      setIsPaymentError(true)
      setErrorCopy("There is an error with your payment")
      setButtonText("Try again")
      // setCardErrors("There was a problem with your payment")
      // setSubmitCopy("Complete Order")
    })
  }


  useEffect(() => {
    if (applePay !== ""){
      console.log("hit here")
      handleSubmit()
    }
  }, [applePay]);



  const handleSubmit = async () => {
    setIsPaymentError(false)
    if ((isSubscriptionItem || isOtpPurchase) && ironclad === ""){
      setIronCladWarining(true)
      return
    }
    if (!isValid.customer || !isValid.shippingAddress) {
      setIsRequiredMessage(true)
    } else {
      setButtonText("Submitting...")
      setCheckoutButtonDisabled(true)
      let values = {
        email: customer?.email || customerData?.email,
        sms_subscribe: customer?.sms_subscribe,
        first_name: shippingAddress.first_name,
        last_name: shippingAddress.last_name,
        address1: shippingAddress.address,
        address2: shippingAddress.address_opt,
        city: shippingAddress.city,
        province: shippingAddress.state,
        zipcode: shippingAddress.zip,
        payment_method: applePay,
        paymentType: "stripe",
        phoneNumber: shippingAddress.phone,
        ...(!!contact_lenses?.prescription?.rightEye?.power && contact_lenses?.prescription?.rightEye?.power !== "oneEye" &&
          {right_eye_power: contact_lenses?.prescription?.rightEye?.power}
        ),
        ...(!!contact_lenses?.prescription?.leftEye?.power && contact_lenses?.prescription?.leftEye?.power !== "oneEye" &&
          {left_eye_power: contact_lenses?.prescription?.leftEye?.power,}
        ),
        one_eye_order: (
          !contact_lenses?.prescription?.leftEye?.power ||
          contact_lenses?.prescription?.leftEye?.power === "oneEye" ||
          !contact_lenses?.prescription?.rightEye?.power ||
          contact_lenses?.prescription?.rightEye?.power === "oneEye"),
        doctor_id: contact_lenses?.doctor?.id,
        line_items: line_items,
        cadence: "28 days",
        country: 'US',
        order_number: '',
        variant_id: '',
        note: gclid,
        utm_campaign: utm_campaign,
        utm_content: utm_content,
        utm_source: utm_source,
        utm_medium: utm_medium,
        website_source: "base",
        source: "base",
        hubbleProductID: '',
        prescriptions: prescriptions,
        price: 1,
        name: "Hubble Customer",
        url: "intake.hubblecontacts.com",
        taxes: taxes,
        isPaidShipping,
        isMemorial2024: isPromo && line_items.some(({contacts_type: t}) => t === "skyhy" || t === "hydro"),
      }

      if (discountCode) values.discount_code = discountCode

      const isSubscriptionItem = cartItems.find(({type, nonSubscription})=> type ==='contacts' && !nonSubscription);

      if (isMilitary && isSubscriptionItem?.lens_quantity === 30) values.isMilitary = isMilitary

      if ((isLifemart && (isSubscriptionItem?.lens_quantity === 30 || line_items.find(item => item.line_item_type === "frame")))) values.isLifemart = isLifemart

      if(!isMobilePay && !paypallPaymentId){
        const cardElement = elements.getElement(CardElement);
        const responseToken = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        })

        if (responseToken.error && !paypallPaymentId) {
          setButtonText("Try again")
          setIsPaymentError(true)
          setErrorCopy("There is an error with your payment")
          setCheckoutButtonDisabled(false)
          return
        }

        const isHydro = !!cartItems.find((item) => item?.isHydro)

        if ( !paypallPaymentId && responseToken.paymentMethod?.card?.funding === "prepaid" && !isHydro) {
          setIsPaymentError(true)
          setButtonText("Try again")
          setCheckoutButtonDisabled(false)
          setIsPaymentError(true)
          setIsSubmittedOrder(false)
          setErrorCopy("Oops! We’re sorry, looks like we can’t complete that transaction. Please note: Hubble does not accept prepaid cards.")
          return false;
        }

        if (responseToken.paymentMethod) {
          values = { ...values, payment_method: await responseToken.paymentMethod.id }
        }


      }


      if (!!paypallPaymentId) {
        values = { ...values, payment_method: paypallPaymentId, paymentType: "braintree"}
      }

      if (window.gtag !== null) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "add_payment_info",
          ecommerce: {
            currency: "USD",
            value: parseFloat(parseFloat(subTotal).toFixed(2)),
            coupon: "N/A",
            paymentType: "stripe",
            items: ga_items,
          }
        });
      }

      if (window.gtag !== null) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "add_shipping_info",
          ecommerce: {
            currency: "USD",
            value: parseFloat(parseFloat(subTotal).toFixed(2)),
            coupon: "N/A",
            paymentType: "stripe",
            items: ga_items,
          }
        });
      }

      if (userToken && !isAgreeDuplicate && cartItems.some(e => !e.nonSubscription && e.title?.toLowerCase().includes("hubble classic"))) {
        const headers = {
          headers: {
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            Authorization: `Bearer ${userToken}`
          }
        }

        const [customer, orders] = await Promise.all([
          axios.get(`${process.env.GATSBY_API_URL}/api/v1/stripe/customers`, headers),
          axios.get(`${process.env.GATSBY_API_URL}/api/v1/stripe/customers/orders`, headers)
        ])

        setCustomerData({...customer.data, type: "stripe"})
        setStripeCustomer({...customer.data, type: "stripe"})

        const classicItem = cartItems.find(e => e.title?.toLowerCase().includes("hubble classic"))

        if (customer.data?.special_discount_flags?.some(e => e.dup_flow) &&
          customer.data.shipping.name === `${shippingAddress.first_name} ${shippingAddress.last_name}` &&
          orders.data?.orders.find(e =>
            e?.lenses?.order_lens_type === "classic" &&
            !["error", "refunded"].includes(e.fulfillment_status.toLowerCase()) &&
            e.lenses.right_eye_power === (classicItem.prescription?.rightEye?.power === "oneEye" ? undefined : classicItem.prescription?.rightEye?.power) &&
            e.lenses.left_eye_power === (classicItem.prescription?.leftEye?.power === "oneEye" ? undefined : classicItem.prescription?.leftEye?.power)
          )) {
          handleShowDuplicateDialog();
          setButtonText("Place Order")
          setCheckoutButtonDisabled(false)
          return;
        }
      }

      if(values.paymentType === "stripe") {
        if(customerData || userToken || newUserToken) {
          stripeSubmitCheckout(values, userToken || newUserToken)
        } else {
          stripeUserCreate(values)
        }
      }
      let checkout_url = `${process.env.GATSBY_API_URL}/stripe/create-combo-charge`

      if (!!paypallPaymentId ) {
        axios.post(checkout_url, values, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          }
        }).then((response) => {
          if (response.data.order_number === "There was an error with your payment" || response.data.order_number === undefined) {
            // setCardErrors("There was an error with your payment" )
            // setSubmitCopy("Complete Order")
            setIsPaymentError(true)
            setCheckoutButtonDisabled(false)
            setButtonText("Try again")
            return
          }
          if (typeof window !== "undefined") {
            if (window.gtag !== null) {
              window.dataLayer.push({ ecommerce: null })
              window.dataLayer.push({
                event: "purchase",
                ecommerce: {
                  transaction_id: `${btoa(customer?.email)}-${Date.now()}`,
                  value: parseFloat(parseFloat(subTotal).toFixed(2)),
                  tax: 0,
                  shipping: 0,
                  currency: "USD",
                  coupon: "N/A",
                  paymentType: "stripe",
                  website_source: "base",
                  items: ga_items
                }
              });
            }
          }
          setButtonText("Place Order")
          // after successful order placement remove the discount code from localStorage
          console.log(response)
          clearOrder()
          navigate("/success-order", { state: { isSuccess: true }, orderNumber: "leotest123", orderType: "contacts" })
        }).catch(() => {
          setCheckoutButtonDisabled(false)
          setIsPaymentError(true)
          setErrorCopy("There is an error with your payment")
          setButtonText("Try again")
        })
      }
    }
  }


  const handleRadio = item => {
    if (item === "credit_card"){
      setPaypallPaymentId(null)
    }

    if (item === "credit_card" && active == "credit_card") {
      return setActive(null)
    }

    if (item === "credit_card" || item === "paypal") {
      setActive(item)
      return setIsValid({ ...isValid, paymentMethod: false })
    }

    const { id, type, paymentSystem, cardNumber } = item

    setActive(id)
    setPaymentMethod({
      type,
      paymentSystem,
      cardNumber,
    })
    setIsValid({ ...isValid, paymentMethod: true })
  }

  const handleClickWrap = () => {
    setIronclad(ironclad === "" ? "ironclad" : "")
    setIronCladWarining(false)
    if (window !== undefined) {
      window._ps("set", "signer_id", customer?.email);
      window._ps("send", "updated", { custom_data: { first_name: customer?.first_name , last_name: customer?.last_name } });
      window._ps("mainwebsite-tos:send", "agreed", {
        // The event_callback function will be invoked once the "send" is complete.
        event_callback: function(err, eventType, clickwrapGroup, payload) {
          if (err) {
            // The send encountered an error.
          }
          setCheckoutButtonDisabled(false)
          // The send is complete and acceptance was captured successfully.
        }
      });
    }
  }

  const renderPaypal = () => {
    const PayPalButton = paypal.Button.driver('react', { React, ReactDOM });

    return <PayPalButton
            braintree={ braintree }
            commit={true }
            env={ process.env.GATSBY_BRAINTREE_ENV }
            client={ {sandbox: 'sandbox_tvynb2kv_jt9yb92tffv8pprd', production: 'production_9qpr3jqc_qvfxwnzxr53brmt8' }}
            payment={ (data, actions) => payment(data, actions) }
            onAuthorize={ (data, actions) => onAuthorize(data, actions) }
            style={{ shape: 'rect', tagline: false }}
          />
  }
  // braintree things

  // paypal payments
  const payment = (data, actions) => {
    const amount = parseFloat(subTotal).toFixed(2)
    return actions.braintree.create({
      flow: 'vault',
      amount: amount,
      currency: 'USD'
    });
  }

  const onAuthorize = (payload, actions) =>  {
    setPaypallPaymentId(payload.nonce)
    // setUser({...values, payment_method:  payload.nonce})
    // setTimeout(function () {
    //     submitUser(values)
    // }, 2000);
  }

  const isSubscriptionItem = cartItems.find(({type, nonSubscription})=> type ==='contacts' && !nonSubscription);
  const subscriptionItemOptions = isSubscriptionItem?.isHydro ? quantityOptionsValues(hubblePricing).hydro : (isSubscriptionItem?.isSkyhy ? quantityOptionsValues(hubblePricing).skyhy : !isLifemart && !isMilitary ? [julyPriceTestClassic] : quantityOptionsValues(hubblePricing).classic)
  const isClassicLenses = !(isSubscriptionItem?.isHydro || isSubscriptionItem?.isSkyhy)
  const lifemartPrice = getValues(subscriptionItemOptions, isSubscriptionItem?.lens_quantity, "lifemartPrice")
  const militaryPrice = getValues(subscriptionItemOptions, isSubscriptionItem?.lens_quantity, "militaryPrice")
  const regularPrice = isSubscriptionItem?.isAvg
    ? isSubscriptionItem.price
    : getValues(subscriptionItemOptions, isSubscriptionItem?.lens_quantity, "price")
  const isMonthly = isSubscriptionItem?.lens_quantity === 30
  const recurringOrderPrice = isSubscriptionItem?.isAvg ? regularPrice : (isMilitary && isMonthly) ? militaryPrice : (isLifemart && isMonthly) ? lifemartPrice : regularPrice
  const isOtpPurchase = cartItems.find(({type, otpPurchase})=> type ==='contacts' && otpPurchase);

  const isLinkPayDisabled = !isValid.shippingAddress || !taxLoaded
  const handleBack = () => {
    navigate(backTo);
    sessionStorage.removeItem('previousPage')
  }

  const handleShowDuplicateDialog = () => {
    setShowDuplicateDialog(true)

    window.dataLayer.push({
      event: "dupe_flow_initiated",
    })
  }

  const handleHideDuplicateDialog = () => {
    // setShippingAddress({
    //   first_name: "",
    //   last_name: "",
    //   company: "",
    //   address: "",
    //   address_opt: "",
    //   city: "",
    //   state: "",
    //   zip: "",
    //   phone: "",
    // })

    const contactsItem = cartItems.find(e => e.type === "contacts")
    const optionsValues = quantityOptionsValues(hubblePricing)[contactsItem.contacts_type]
    const duplicatePrice = getValues(optionsValues, contactsItem.lens_quantity, "duplicatePrice")

    const newContactsItem = {
      ...contactsItem,
      price: duplicatePrice,
      is_duplicate_item: true,
    }


    updateItem(newContactsItem, newContactsItem.cart_item_id)
    setIsAddressShouldReset(true)
    setIsPaymentError(false)
    setShowDuplicateDialog(false)
    setMobilePay(false)
    setPaypallPaymentId(null)
    setApplePay("")
    if (!userToken) {
      setNewUserToken("")
      setCustomerData(null)
    }
    setCheckoutButtonDisabled(false)
  }

  const handleSubmitDuplicateDialog = () => {
    const contactsItem = cartItems.find(e => e.type === "contacts")
    const optionsValues = quantityOptionsValues(hubblePricing)[contactsItem.contacts_type]
    const duplicatePrice = getValues(optionsValues, contactsItem.lens_quantity, "duplicatePrice")

    const newContactsItem = {
      ...contactsItem,
      price: duplicatePrice,
      is_duplicate_item: true,
    }

    updateItem(newContactsItem, newContactsItem.cart_item_id)
    setIsPaymentError(false)
    setShowDuplicateDialog(false)
    setIsAgreeDuplicate(true)

    window.dataLayer.push({
      event: "dupe_accepted",
    })
  }

  useEffect(() => {
    if (isAgreeDuplicate) handleSubmit()
  }, [isAgreeDuplicate]);

    return (
    <div className={`checkout-customer-info ${isNewRx ? "new-rx-flow" : ""}`}>
      <a className="back-button" onClick={handleBack}>
        <FontAwesomeIcon icon={faArrowLeft} />
        <span className="text h8">Back</span>
      </a>
      <h2 className="heading text bulky-wide h2">Checkout</h2>
      <div className="customer-info">
        <CheckoutContact prescriptionEmail={contact_lenses?.email || customer?.email} emojiErrors={emojiErrors} setEmojiErrors={setEmojiErrors} />
        <CheckoutShippingAddress emojiErrors={emojiErrors} setEmojiErrors={setEmojiErrors} />

        {(isNewRx && !isNewRxDesktop) && <CheckoutOrderInfo />}

        <CheckoutBlock id="payment">
          <CheckoutBlock.Header title="Payment Method" />
          <CheckoutBlock.Body>
            {/* {userToken ? (
              <SignedPayment
                isValid={isValid.paymentMethod}
                setIsValid={value =>
                  setIsValid({ ...isValid, paymentMethod: value })
                }
                active={active}
                handleRadio={handleRadio}
                setPaymentMethod={setPaymentMethod}
              />
            ) : ( */}

              <div className="payment-methods">
                <div className={`radio-container ${isNewRxDesktop ? "new-rx-desktop" : ""}`}>
                  <div className="checkout-radio">
                    <div className="radio-head payment-method-wrapper">
                      <span className="name text h8 neutral">Credit/Debit Card</span>
                      <WebpImage className={`payment-logo ${isNewRxDesktop ? "new-rx-desktop" : ""}`} fileName="Pages/Checkout/cards.svg" alt="credit cards"/>
                    </div>
                    <div className="radio-body">
                      <CardElement/>
                    </div>
                  </div>

                {
                 !(isMilitary || isLifemart) &&
                  <div>
                    {!hasContactsAvg && !isOtpPurchase && renderPaypal()}
                  </div>
                }

                {paymentRequest && (
                  <div>
                    <PaymentRequestButtonElement
                      className={`mobile-pay ${isLinkPayDisabled ? 'disabled-btn' : ''}`}
                      options={{
                        paymentRequest,
                        style: {
                          paymentRequestButton: {
                            height: isMobile ? '45px' : '55px',
                          },
                        }
                      }}
                      onClick={updatePaymentRequest}
                    />
                  </div>
                )}
                </div>

                {/* <div className="radio-container">
                  <CheckoutRadio
                    value="paypal"
                    onClick={() => handleRadio("paypal")}
                    active={active}
                  >
                    <CheckoutRadio.Header label="Paypal" />
                  </CheckoutRadio>
                </div> */}
              </div>
            {/* )} */}
          </CheckoutBlock.Body>
          {(isNewRx || isNewRxDesktop) &&
            <>
              {isSubscriptionItem && <p className="checkout-tip text h10 granite">
                After you complete your order, Hubble will charge your payment method for the discounted
                total and then ${isClassicLenses ?
                  `${Number(recurringOrderPrice * (isSubscriptionItem?.isOneEye ? 1 : 2)).toFixed(2)} `
                  :
                  `${Number(recurringOrderPrice).toFixed(2)}
                   ${isSubscriptionItem.isOneEye ? "" : "per eye "}`
                } + $3.99 S/H (plus tax where applicable) {
                isSubscriptionItem.isAvg
                  ? getCadence(isSubscriptionItem.cadence).toLowerCase()
                  : `every ${isSubscriptionItem.cadence} weeks`
                } until you modify
                or cancel your subscription, which you can do at any time through the{" "}
                <a href="/login">customer portal</a> or by <a href="/contact-us">contacting us</a>.
              </p>}
              {(isSubscriptionItem || isOtpPurchase) &&
              <div className={`ironclad-wrapper ${ironcladWarning ? "warning" : ""}`} >
                <PrescriptionRadio
                  value="ironclad"
                  label={
                    isOtpPurchase ?
                      `I agree to <a href="https://tos.hubblecontacts.com/" target="_blank">Hubble’s Terms of Service</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>`
                      :
                      `I agree to sign up for a Hubble subscription and to <a href="https://tos.hubblecontacts.com/" target="_blank">Hubble’s Terms of Service</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>`
                  }
                  onClick={() => handleClickWrap()}
                  active={ironclad}
                />
              </div>
            }
            </>
          }
        </CheckoutBlock>

        {(isNewRx || isNewRxDesktop) &&
          <>
            <NewButton
              className='new-rx'
              type="button"
              label={buttonText}
              disabled={checkoutButtonDisabled}
              onClick={handleSubmit}
            />
            {Object.values(emojiErrors).some(e => e === true)
              ? <div className="error-block">Emojis are not allowed</div> :
              isPaymentError && <div className="error-block">{errorCopy}</div>}
          </>
        }

        <CheckoutDuplicateModal
          show={showDuplicateDialog}
          onHide={handleHideDuplicateDialog}
          onSubmit={handleSubmitDuplicateDialog}
        />

        {!isNewRx && !isNewRxDesktop &&
        <CheckoutBlock id="summary">
        {(isValid.customer || isValid.shippingAddress || isValid.paymentMethod || isValid.billingAddress) &&
        <CheckoutBlock.Header title="Summary" />}
          <CheckoutBlock.Body>
            <div className="customer-brief">
              {isValid.customer && (
                <BriefField
                  title="Contact"
                  description={customer?.email || customerData?.email}
                  disabled={!!userToken}
                  to="contact"
                />
              )}
              {isValid.shippingAddress && (
                <BriefField
                  title="Ship to"
                  description={`${shippingAddress.first_name} ${shippingAddress.last_name}\n ${shippingAddress.address}, ${!!shippingAddress?.address_opt ? shippingAddress?.address_opt : ""}${!!shippingAddress?.address_opt ? "," : ""} ${shippingAddress.city}, ${shippingAddress.state}, ${shippingAddress.zip}`}
                  to="shipping"
                />
              )}
              {isValid.paymentMethod && (
                <BriefField
                  title="Payment method"
                  description={`${paymentMethod.paymentSystem} | ${paymentMethod.cardNumber}`}
                  to="payment"
                />
              )}
              {isValid.billingAddress && (
                <BriefField
                  title="Billing"
                  description={`${billingAddress.address}, ${billingAddress.city}, ${billingAddress.state}, ${billingAddress.zip}`}
                  to="billing"
                />
              )}
            </div>
            {isClassicLenses && isSubscriptionItem &&
              <p className="checkout-tip text h8 ">
                Beginning at least 14 days after you complete your order, Hubble will charge your payment method{" "}
                ${isClassicLenses ?
                  `${recurringOrderPrice * (isSubscriptionItem.isOneEye ? 1 : 2)} `
                  :
                  `${recurringOrderPrice}
                   ${isSubscriptionItem.isOneEye ? "" : "per eye "}`
                }
                plus $3.99 S/H (plus tax where applicable) every {`${isSubscriptionItem.cadence} ${isSubscriptionItem.lens_quantity === 30 ? "weeks" : "months"} `}
                until you modify or cancel your subscription,
                which you can do at any time through the {" "}
                <Link to="/login">customer portal</Link>{" "}or by{" "}
                <Link to="/contact-us">contacting us.</Link>{" "}
              </p>
            }
            {!isClassicLenses && isSubscriptionItem &&
              <p className="checkout-tip text h8 ">
                After you complete your order, Hubble will charge your payment method for the discounted total and then {" "}
                ${isClassicLenses ?
                  `${recurringOrderPrice * (isSubscriptionItem.isOneEye ? 1 : 2)} `
                  :
                  `${recurringOrderPrice}
                   ${isSubscriptionItem.isOneEye ? "" : "per eye "}`
                }
                plus $3.99 S/H (plus tax where applicable) every {`${isSubscriptionItem.cadence} ${isSubscriptionItem.lens_quantity === 30 ? "weeks" : "months"} `}
                until you modify or cancel your subscription,
                which you can do at any time through the {" "}
                <Link to="/login">customer portal</Link>{" "}or by{" "}
                <Link to="/contact-us">contacting us.</Link>{" "}
              </p>
            }
            <br/>
            {isSubscriptionItem &&
              <div className={`ironclad-wrapper ${ironcladWarning ? "warning" : ""}`} >
                <PrescriptionRadio
                  value="ironclad"
                  label={`I agree to sign up for a Hubble subscription and to <a href="https://tos.hubblecontacts.com/" target="_blank">Hubble’s Terms of Service</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>`}
                  onClick={() => handleClickWrap()}
                  active={ironclad}
                />
              </div>
            }

            <br/>
            <NewButton
              type="button"
              label={buttonText}
              disabled={checkoutButtonDisabled}
              onClick={handleSubmit}
            />

            {isPaymentError && <div className="error-block">{errorCopy}</div>}

          </CheckoutBlock.Body>
        </CheckoutBlock>}

      </div>
    </div>
  )
}




const BriefField = ({ title, description, to, disabled }) => {
  return (
    <div className="brief-field" aria-disabled={disabled}>
      <p className="title text h11 neue-bold granite uppercase">{title}</p>
      <div className="description">
        <span className="text h8 neutral">{description}</span>
        {!disabled && (
          <FontAwesomeIcon
            icon={faPen}
            onClick={() => scrollTo(`.checkout-block#${to}`)}
          />
        )}
      </div>
    </div>
  )
}



export default CheckoutCustomerInfo
